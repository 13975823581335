@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Montserrat-Light";
  /* font-size: .8rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background:
    linear-gradient(-45deg, #d61d64, #2e5ae9, #8b0981, #05299e);
  background-position: center center;
  background-size: 400% 400%;
  min-height: 100vh;
  width: 100%;
  animation: gradient 10s ease infinite;
  background-position: center center;
  background-size: 400% 400%;
  scroll-behavior: smooth;
  animation: gradient 8s ease infinite;
  scroll-padding: 500px !important;
}

::-webkit-scrollbar {
  display: none;
}

#nSc::-webkit-scrollbar {
  display: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.fixed-w {
  max-width: 1320px;
  margin: 0 auto;
}


HTML {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(./fonts/Montserrat/static/Montserrat-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(./fonts/Montserrat/static/Montserrat-SemiBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url(./fonts/Montserrat/static/Montserrat-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.dynamic-text li span {
  position: relative;
}

.dynamic-text li span::after {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  background: rgb(15, 23, 42);
  width: 100%;
  border-left: 2px solid white;
  animation: typing 3s steps(4) infinite;
}

@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}



/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #d61d64;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: white;
  border: 4px solid #d61d64;
  top: -4px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
  transform: translateX(100%);
  visibility: hidden;
  transition: all .42s ease-in-out;
}

.left.visible {
  transform: translateX(0);
  visibility: visible;
  z-index: 2;
}

/* Place the container to the right */
.right {
  left: 50%;
  transform: translateX(-100%);
  visibility: hidden;
  transition: all .42s ease-in-out;
  /* transform: translateX(3px); */
}

.right.visible {
  transform: translateX(0);
  visibility: visible;
  z-index: 2;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid rgba(0, 0, 0, 0.363);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.363);
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid rgba(0, 0, 0, 0.363);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(0, 0, 0, 0.363) transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -13px;
}

/* The actual content */
.content {
  /* visibility: hidden; */
  padding: 20px 30px;
  /* background-color: ; */
  /* position: relative; */
  border-radius: 6px;
  /* transform: translateX(100%);
  transition: all .42s ease-in-out; */
}


/* .content.visible {
  visibility: visible;
  transform: translateX(0%);
} */

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1150px) {

  /* Place the timelime to the left */
  .timeline::after {
    left: 20px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 45px;
    padding-right: 15px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 35px;
    border: medium solid rgba(0, 0, 0, 0.363);
    border-width: 10px 10px 10px 0;
    border-color: transparent rgba(0, 0, 0, 0.363) transparent transparent;
  }

  /* .content {
    transform: translateX(0%);
  } */

  /* .content.visible {
    background:green;
  } */

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 7px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
    transform: translateX(50%)
  }

  .left {
    left: 0;
    transform: translateX(50%) !important;
    visibility: hidden !important;
    transition: all .42s ease-in-out;  
  }

  .left.visible {
    visibility: visible !important;
    transform: translateX(0%) !important;
    z-index: 2;
  }
}