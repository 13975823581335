:root {
  --default-color: rgb(1.5, 1.5, 1.5);
  --darker-color-variant: 8, 8, 8;
  --darker-color: rgb(var(--darker-color-variant));
  --third-color: rgb(17, 17, 17);
  --third-color-transparent: rgba(17, 17, 17, .55);
  --third-color-2: rgb(15, 15, 15);
  --default-light-color-2: #2a2b2a18;
  --default-light-color: #2a2b2a48;
  --secondary-light-variant: 0, 144, 255;
  --secondary-light-color: rgb(var(--secondary-light-variant));
  // --secondary-light-color: rgb(var(--secondary-light-variant));
  --secondary-light-color-2: rgba(var(--secondary-light-variant), 0.404);
  --secondary-light-color-3: rgba(var(--secondary-light-variant), 0.094);
  --text-primary-color: #f5f5f5;
  --text-secondary-color: #636161;
  --text-secondary-color-light: #ececec;
  --text-secondary-color-light-2: #ecececab;
  --text-secondary-color-2: #79797931;
  --max-w: 1180px;
  --max-w-2: 1000px;
  --max-w-3: 900px;
  --nav-max-w: 1030px;
  --pad-to-mw: calc(50% - var(--max-w)/2);
}


.w-wrapped {
  min-width: calc(var(--max-w)/4);

  @media only screen and (max-width:780px) {
    min-width: calc((50lvw)/2);
  }
}

.font-MontRegular {
  font-family: "Montserrat-Regular";
}

.font-MontMedium {
  font-family: "Montserrat-Medium";
}

.font-MontSemiBold {
  font-family: "Montserrat-SemiBold";
}

.font-MontBold {
  font-family: "Montserrat-Bold";
}

.font-MontLight {
  font-family: "Montserrat-Light";
}

.animate_text {
  background: linear-gradient(45deg, #f31f70, rgb(9, 120, 247), #f80ee5);
  background-size: 1000% 400%;
  background-clip: text;
  color: transparent;
  animation: gradient 5s ease infinite;
}

.animate_box {
  background: linear-gradient(45deg, #f31f70, rgb(9, 120, 247), #f80ee5);
  background-size: 1000% 400%;
  color: white;
  animation: gradient 10s ease infinite;
}

.animate_box2 {
  background: linear-gradient(45deg, #4949492a, rgba(5, 33, 68, 0.349), #f31f7044, rgba(9, 120, 247, 0.288), #5a08538e);
  background-size: 1000% 400%;
  color: white;
  animation: gradient 10s ease infinite;
}

.animate_box3 {
  background: linear-gradient(45deg, #0f0f0fe3, rgba(1, 9, 19, 0.918), #1d030def, rgba(1, 16, 34, 0.918), #180216e0);
  background-size: 1000% 400%;
  color: rgb(15, 23, 42);
  animation: gradient 5s ease infinite;
}

.visibleA {
  visibility: visible;
  transform: translateY(0);
}

.visibleB {
  visibility: visible;
  transform: translateX(0) scale(1);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.slider {
  /* height: 335px; */
  /* margin: auto; */
  margin-top: 2rem;
  position: relative;
  width: 98%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slider-container {
  display: flex;
  width: calc(335px * 10);
  cursor: pointer;
  animation: scroll 35s linear infinite;
}

.slider-container:hover {
  animation-play-state: paused;
}

.slide {
  /* height: 335px; */
  width: 345px;
  display: flex;
  align-items: center;
  padding: 15px;
}

.slide img {
  width: 100%;
  transition: all .2s ease-in-out;
}

.slide img:hover {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-335px * 6));
  }
}

.navLinks {
  font-family: 'Montserrat-SemiBold';
  transition: all .2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 10px 0;

  /* after:absolute after:bottom-0 after:left-0 after:h-[2px] hover:after:w-full after:transition-all after:w-[0%] after:bg-[#d61d64] py-2 */
}

.navLinks:hover {
  color: #d61d64;
}

.navLinks.active {
  color: #d61d64;
}

.navLinks.active::after {
  content: "";
  transition: all .2s ease-in-out;
  background: #d61d64;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  z-index: 0;
  width: 100%;
}

.navLinks::after {
  content: "";
  transition: all .2s ease-in-out;
  background: #d61d64;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  z-index: 0;
  width: 0%;
}

.navLinks:hover::after {
  width: 100%;
}


.points {
  width: 15px;
  height: 15px;
  border-radius: 999px;
  border: 2px solid #d61d64;
  transition: all .12s ease-in-out;
}

.points.active {
  background: #d61d64;
  border: none;
  transform: scale(1.2);
}

/* w-[15px] rounded-full bg-[#f31f70] h-[15px] scale-[1.2] */


#portfolio {
  position: relative;
}

#folio-carousel {
  background-color: transparent;
  padding-left: var(--pad-to-mw);

  @media screen and (max-width:1024px) {
    padding-left: 1.5vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}